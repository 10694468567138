import React, { useState } from "react";
import useInterview from "shared/hooks/useInterview";
import Editor from "react-simple-code-editor";
import { js_beautify, css_beautify } from "js-beautify";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-css";
import "prismjs/components/prism-markup";
import "prismjs/themes/prism-okaidia.css";
// import "styles/code-editor-theme.scss"; //TODO: Edit this custom theme if necessary

import firstDot from "assets/svgs/code-dot-1.svg";
import secondDot from "assets/svgs/code-dot-2.svg";
import thirdDot from "assets/svgs/code-dot-3.svg";
import styles from "./CodeEditor.module.scss";
import { formatHTML } from "utils";

type Props = {
  value: string;
  language: string;
  questionId?: number;
  editable?: boolean;
};

enum ProgrammingLanguage {
  JS = "js",
  HTML = "html",
  CSS = "css",
}

const CodeEditor: React.FC<Props> = ({ value, editable, language, questionId }) => {
  const [code, setCode] = useState("");
  const { update } = useInterview();

  const determineBeautifier = (value: string) => {
    switch (language) {
      case ProgrammingLanguage.CSS:
        return css_beautify(value);
      case ProgrammingLanguage.HTML:
        return formatHTML(value);
      default:
        return js_beautify(value);
    }
  };

  const determineHighlightLanguage = () => {
    switch (language) {
      case ProgrammingLanguage.CSS:
        return languages.css;
      case ProgrammingLanguage.HTML:
        return languages.markup;
      default:
        return languages.js;
    }
  };

  return (
    <>
      <div className={styles.dots}>
        <img src={firstDot} className={styles.dot} alt="" />
        <img src={secondDot} className={styles.dot} alt="" />
        <img src={thirdDot} className={styles.dot} alt="" />
      </div>
      <Editor
        placeholder="Type your answer here!"
        value={editable ? code : determineBeautifier(value)}
        onValueChange={
          editable
            ? (code) => {
                update(questionId, code);
                return setCode(code);
              }
            : null
        }
        disabled={!editable}
        highlight={(value) => highlight(value, determineHighlightLanguage())}
        textareaClassName={styles.customPadding}
        preClassName={styles.customPadding}
        style={editorStyles}
      />
    </>
  );
};

const editorStyles: React.CSSProperties = {
  fontFamily: "Mulish",
  fontWeight: 500,
  fontSize: 20,
  borderRadius: "12px",
  boxShadow: "0px 8px 24px rgba(25, 32, 56, 0.12)",
  position: "relative",
  zIndex: 2,
  backgroundColor: "#001122",
  color: "#ffffff",
};

export default CodeEditor;
