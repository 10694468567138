import React from "react";
import InterviewContent from "sections/InterviewContent";
import { InterviewContextProvider } from "shared/context/InterviewContext";

const InterviewContentPage = () => {
  // Interview page should not be public yet
  return <div></div>;
  
  return (
    <InterviewContextProvider>
      <InterviewContent />
    </InterviewContextProvider>
  );
};

export default InterviewContentPage;
